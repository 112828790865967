@charset "UTF-8";
/*
Name: High contrast (W/B)
Version: 1.01
Author: Opera Software ASA
Description: High contrast, white on black.

Copyright © 2003 Opera Software ASA.
*/

* {
	background: Black !important;
	color: White !important;
}

input, textarea, select {
	background: White !important;
	color: Black !important;
}

button, input[type="file"], input[type="submit"], input[type="button"], input[type="reset"] {
	background: Silver !important;
	color: Black !important;
}

a[href] {text-decoration: underline !important;}

a[href]:hover {
	background: White !important;
	color: Black !important;
}

a[href] img {border: thin solid White !important;}

@media print {
* {
	background: White !important;
	color: Black !important;
}
}

